@font-face {
  font-family: 'flexibull-2-0';
  src: url('../font/flexibull-2-0.eot?3760478');
  src: url('../font/flexibull-2-0.eot?3760478#iefix') format('embedded-opentype'),
       url('../font/flexibull-2-0.woff2?3760478') format('woff2'),
       url('../font/flexibull-2-0.woff?3760478') format('woff'),
       url('../font/flexibull-2-0.ttf?3760478') format('truetype'),
       url('../font/flexibull-2-0.svg?3760478#flexibull-2-0') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'flexibull-2-0';
    src: url('../font/flexibull-2-0.svg?3760478#flexibull-2-0') format('svg');
  }
}
*/
[class^="flexibull-"]:before, [class*=" flexibull-"]:before {
  font-family: "flexibull-2-0";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.flexibull-right-dir:before { content: '\e800'; } /* '' */
.flexibull-left-dir:before { content: '\e801'; } /* '' */
.flexibull-up-dir:before { content: '\e802'; } /* '' */
.flexibull-down-dir:before { content: '\e803'; } /* '' */
.flexibull-clock:before { content: '\e804'; } /* '' */
.flexibull-lock-open:before { content: '\e805'; } /* '' */
.flexibull-plus:before { content: '\e806'; } /* '' */
.flexibull-attach:before { content: '\e807'; } /* '' */
.flexibull-right:before { content: '\e808'; } /* '' */
.flexibull-left:before { content: '\e809'; } /* '' */
.flexibull-lock:before { content: '\e80a'; } /* '' */
.flexibull-mail:before { content: '\e80b'; } /* '' */
.flexibull-phone:before { content: '\e80c'; } /* '' */
.flexibull-sort-numeric:before { content: '\e80d'; } /* '' */
.flexibull-sort-alphabet:before { content: '\e80e'; } /* '' */
.flexibull-calendar:before { content: '\e80f'; } /* '' */
.flexibull-upload:before { content: '\e810'; } /* '' */
.flexibull-upload-cloud:before { content: '\e811'; } /* '' */
.flexibull-download:before { content: '\e812'; } /* '' */
.flexibull-ok:before { content: '\e813'; } /* '' */
.flexibull-cancel:before { content: '\e814'; } /* '' */
.flexibull-home:before { content: '\e815'; } /* '' */
.flexibull-doc:before { content: '\e816'; } /* '' */
.flexibull-up:before { content: '\e817'; } /* '' */
.flexibull-down:before { content: '\e818'; } /* '' */
.flexibull-cancel-1:before { content: '\e819'; } /* '' */
.flexibull-spin4:before { content: '\e834'; } /* '' */
.flexibull-spin6:before { content: '\e839'; } /* '' */
.flexibull-firefox:before { content: '\e840'; } /* '' */
.flexibull-menu-1:before { content: '\f008'; } /* '' */
.flexibull-docs:before { content: '\f0c5'; } /* '' */
.flexibull-menu:before { content: '\f0c9'; } /* '' */
.flexibull-doc-text:before { content: '\f0f6'; } /* '' */
.flexibull-angle-left:before { content: '\f104'; } /* '' */
.flexibull-angle-right:before { content: '\f105'; } /* '' */
.flexibull-angle-up:before { content: '\f106'; } /* '' */
.flexibull-angle-down:before { content: '\f107'; } /* '' */
.flexibull-spinner:before { content: '\f110'; } /* '' */
.flexibull-file-pdf:before { content: '\f1c1'; } /* '' */
.flexibull-file-word:before { content: '\f1c2'; } /* '' */
.flexibull-file-excel:before { content: '\f1c3'; } /* '' */
.flexibull-file-powerpoint:before { content: '\f1c4'; } /* '' */
.flexibull-file-image:before { content: '\f1c5'; } /* '' */
.flexibull-file-archive:before { content: '\f1c6'; } /* '' */
.flexibull-file-audio:before { content: '\f1c7'; } /* '' */
.flexibull-file-video:before { content: '\f1c8'; } /* '' */
.flexibull-envelope-open:before { content: '\f2b6'; } /* '' */
.flexibull-envelope-open-o:before { content: '\f2b7'; } /* '' */
